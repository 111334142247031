<template>
	<prac-test :test=test></prac-test>
</template>
<script>
import { PracTest } from "@/components";

export default {
  name: "practice-tests",
  components: {
    PracTest
  },
  data() {
    return {
      test: {
        date: "February 2019",
        title: "Scaling Teams",
        subtitle: "Rules for focus in a distracted world",
        description:
          "Online practice test on the book Deep work by Cal Newport.",
        bg: "/img/tests/prod/deep-work-bg6.jpg",
        author: "Dr. Cal Newport",
        references:
          "Deep Work: Rules for Focused Success in a Distracted World (2016)",
        recommendation:
          "The book deep work is for everybody who wants to increase their productivity: to do more in less time. It's a must read for every knowledge worker. ",
        items: [
          {
            front:
              'What are the main reason that people are in the mode of "shallow work" instead of "deep work"?',
            back:
              "Distractions and social pressure that are increasingly present in our world of endless information."
          },
          {
            front:
              "Why spend people nowadays less time prioritizing tasks in today's corporate world?",
            back:
              "Because people chose what's easiest in their mode of shallow work. In addition, we can measure speed as the amount of tasks we complete. However, we cannot measure depth."
          },
          {
            front:
              "What does it mean to inject the occasional <b>dash of rooseveltian intensity</b> into your own workday?",
            back:
              "<ul><li>To identify a task that requires deep work to complete that's high on your priority list.</li><li>Estimate how long you'd normally put aside for an obligation of this type,</li><li>then give yourself a hard deadline that drastically reduces this time.</li><li>If possible, commit publicly to the deadline - for example telling a person that you have it finished.</li><li>Motivate yourself by setting a countdown timer.</li>"
          },
          {
            front: "<h4>Why should you not stay <i>connected 24/7</i>?</h4>",
            back:
              "Research shows that we can stay focused for about 4 hours a day. Being connected 24/7 forces us to spread those 4 hours of focused work to 12 hours. As a result, the output suffers."
          },
          {
            front:
              "Why is it easier to focus using the <i>roosevelt-dash method</i>?",
            back:
              "If you give in to distraction, you will fail to finish on your deadline."
          },
          {
            front:
              'What is meant by "people use busyness as a <i>proxy for productivity</i>"?',
            back:
              "The absence of clear indicators of what is productive makes people do many things with little depth. You may send an e-mail every second hour and have the impression that you are productive, however, in the end of the day you do not get anything done that has depth (and normally more value)."
          },
          {
            front: 'Why is the <b>"deep life"</b> not for everybody?',
            back:
              "Because people chose what's easiest in their mode of shallow work. In addition, we can measure speed and the amount of tasks we complete. However, we cannot measure depth."
          },
          {
            front:
              "Why is it advantageous to perform professional activities in a state of distraction free concentration that push your cognitive ability to their limit?",
            back:
              "These efforts create new value, improve your skills, and are hard to replicate."
          },
          {
            front: "<h4>What is shallow work?</h4>",
            back:
              "Shallow wok is less cognitively demanding that is often performed while distracted. The value that results by shallow work is relatively small and the work can easily be replicated."
          },
          {
            front: "What are examples of shallow work?",
            back:
              "Checking emails, twitter, facebook. But basically everything that you do while not fully focused."
          },
          {
            front:
              "What can you do to spend more hours in a state of deep work?",
            back:
              "Schedule your work that requires deep work and cancel out all distractions in those periods. Be aware, that most companies do not provide an environment that is beneficial for deep work."
          },
          {
            front:
              "Why is deep work in the present day more important than in the past?",
            back:
              "Today, your work can reach a huge audience. However, as you compete with many professionals, it is easy to find a better alternative with little effort. Furthermore, in the current world that is changing rapidly, the ability to learn things quickly is crucial."
          },
          {
            front:
              "How do studies of consciousness support that 'deep work is of great value'?",
            back:
              "Your experienced are shaped by what you pay attention to. In shallow work you focus on thing that are of little importance and meaning."
          },
          {
            front:
              "What is a problem with the trend of companies pushing employees to use Facebook and other social media channels?",
            back:
              "Makes deep work much harder. Being connected all the time does not help in doing revolutionary work."
          },
          {
            front: "<h4>How can you make deep work a part of your life?</h4>",
            back:
              "Remove distractions in your environment and distraction that come fom within."
          },
          {
            front: "To focus on deep work, what addiction must you escape?",
            back:
              "The addiction of distraction which many people suffer today from."
          },
          {
            front: "What is a <i>monastic philosophy</i> (lifestyle)?",
            back:
              "To shut oneselve completely off from the world to focus on some sort of work. It's about doing one thing exceptionally well. This approach gives clarity that enables the elimination of many shallow concerns that often overtake those with more varied concerns."
          },
          {
            front: "Why is deep work exhausting?",
            back:
              "Deep work is exhausting because it pushes you toward the limit of your abilities."
          },
          {
            front: "Why is deep work rare nowadays?",
            back:
              "Shallow work is much easier. On top of that, most companies and environments promote us to do shallow work: business as a proxy for productivity, constant connectivity, ..."
          },
          {
            front: "At work, should you still spend efforts on shallow work?",
            back:
              "You should still continue a few core shallow efforts. Some is necessary as it would cause problems and resentment otherwise."
          },
          {
            front: "What are the properties of deep work?",
            back:
              "Intense focus, absence of distractions, single-tasking, for extended periods of time."
          },
          {
            front: "Which are the four rules to promote deep work?",
            back:
              "Embrace boredom, work deeply, quit social media and drain the shallows."
          },
          {
            front: "Why is 'extended periods of time' necessary for deep work?",
            back:
              "To avoid attention residue – when you think about a task that persists and intrudes while performing another task."
          },
          {
            front: "Why is <b>attention residue</b> bad for productivity?",
            back:
              "It makes it almost imossible to work efficiently when your attention strays."
          },
          {
            front: "<h4>Why is deepwork a skill?</h4>",
            back:
              "The more deep work you do, the better you get.  You basically have to learn to focus. The concentration and energy of a newbie will be less. You have to overcome the desire for distration."
          },
          {
            front: "What is a <i>rhythmic philosophy</i> (lifestyle)?",
            back:
              "A fixed time for deep work (e.g. very day from 6-9 am). This involves making deep work a daily habit."
          },
          {
            front: "What is a <i>journalistic philosophy</i> (lifestyle)?",
            back:
              "To schedule deep work whenever the situation allows it. This <i>journalistic approach</i>i> is hard to get right as switching from shallow work to deep work is a skill that one does not learn naturally."
          },
          {
            front: "What is a <i>bimodal philosophy</i> (lifestyle)?",
            back:
              "With a <i>bimodal approach</i> one dedicates fixed time slot for deep work (e.g. every day from 6-9 am). Outside of the defined time slots for deep work shallow work is employed. During deep sessions one follows a monastic approach."
          },
          {
            front: "Why are ad hoc approaches to deep work less effective?",
            back: "Because ad hoc approaches heavily depend on will power."
          },
          {
            front:
              "With what quote from <i>Winifred Gallagher</i> does Cal Newport end his book with?",
            back:
              "I will live the focused life, because it's the best kind there is.\", Winifred Gallagher"
          },
          {
            front:
              "Why does shallow work persist in large quantities in knowledge work?",
            back:
              "One reason is because we rarely see the total impact of such efforts on our schedules."
          },
          {
            front: "Regarding e-mail, what are ways for optimization?",
            back:
              "<ul><li>Instead of listing on your website your general-purpose e-mail address, list different individuals that can be contacted for specific purposes.</li> <li>Sender filter</li> <li>Propose specific times if someone asks to meet with you.</li></ul>"
          },
          {
            front:
              "How much time should a person spend on shallow work on average?",
            back: "30% - 50%"
          }
        ]
      }
    };
  }
};
</script>
